import { GetServerSideProps } from "next";
import React, { ReactElement } from "react";
import { getPageProps } from "@services/initial-calls";
import dynamic from "next/dynamic";
import InsuranceSuperSaverWrapper from "@components/layouts/insuranceSuperSaverWrapper";
import MainBanner from "@components/insurancesupersaver/mainBanner";

const WhyUs = dynamic(() => import("@components/insurancesupersaver/whyUs"));

export default function InsuranceSuperSaverHome(): ReactElement {
    return (
        <InsuranceSuperSaverWrapper page="homePage">
            <MainBanner />
            <WhyUs />
        </InsuranceSuperSaverWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
