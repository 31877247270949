import React, { ReactElement } from "react";
import SellingBox from "@components/insurancesupersaver/sellingBox";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "@redux/reducers";
import Image from "next/image";
import { useDomainContext } from "@hooks/useDomainContext";

export default function MainBanner(): ReactElement {
    const { insuranceCategory } = useSelector(
        (state: RootState) => state.rootReducer.insurancesupersaver.sellingBox,
    );

    const { visitDetails, formattedTitle } = useDomainContext();
    const bestCompany = `${visitDetails?.region} Best ${
        insuranceCategory?.name ?? ""
    } Companies`;

    return (
        <>
            <div className={styles["main-banner"]}>
                <main
                    className={`${styles["main-banner-container"]} max-w-7xl mx-auto block px-4vw lg:px-4`}
                >
                    <div className={styles["content"]}>
                        <span>
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html:
                                        formattedTitle ??
                                        `Unlock Big Savings on <span class="capitalize">${
                                            insuranceCategory?.name ?? ""
                                        }</span> Today`,
                                }}
                            ></h1>
                        </span>
                        <p className="text-xs lg:text-base font-light mt-3">
                            Select your insurance type and enter your zip code
                            below, and we'll instantly show you the top
                            companies in your area:
                        </p>
                        <div className={styles["selling-box"]}>
                            <SellingBox />
                        </div>
                        {visitDetails?.region && (
                            <div className={styles["city-best"]}>
                                {bestCompany}
                            </div>
                        )}
                    </div>
                </main>

                <div className={`${styles["image"]}`}>
                    <Image
                        src="/insurancesupersaver/assets/images/background-imageOpt.jpg"
                        className={styles["background-img"]}
                        alt="auto-banner"
                        layout="fill"
                        priority

                        // width={1500}
                        // height={1730}
                    />
                </div>
            </div>
        </>
    );
}
